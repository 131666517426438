<template>
    <title-bar :title="$t('navigations.types')"/>
    <resource-listing :resource="$EntityOptions" param-type="TYPE" base-url="types" add-button="messages.addNew" delete-message="messages.typeDelete"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
